import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { media } from "utils/Media"
import { Controller, Scene } from 'react-scrollmagic';
import { graphql, StaticQuery } from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import ButtonHold from "components/shared/ButtonHold"

const Wrapper = styled.div`
  overflow: hidden;
  background-color: #e58338;
  
  &.black-bg {
      background-color: ${props => props.theme.colors.black};
  }
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props =>
      props.active &&
      css`
        display: block !important;
      `}

    @media screen and (max-width: ${props => props.theme.sizes.sm}) {
      padding-bottom: 0 !important;
    }
    
    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props =>
      props.active &&
      css`
        height: 100vh;
      `}
    
    @media ${media.sm} {
        height: 100vh;
    }

    &.auto-height {
        height: auto;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }

    .ground-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        z-index: 10;
    }

    .sun-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        transform: translate(0px, 35vh);
        transition: all 0.7s ease-out;

        &.active {
          transform: translate(0px, 0vh);
        }
    }
`

const Content = styled.div`
  position: absolute;
  top: 4vw;
  left: 4vw;
  width: 35vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 12;

  p {
    font-size: 1.5em;
    font-family: ${props => props.theme.font.family.semiBold};
    color: #c12518;

    &.fadeIn {
      opacity: 0;
      transition: all 0.7s ease-out;

      &.active {
        opacity: 1;
      }
    }
  }

  .num {
    font-size: 16rem;
    line-height: 1;
  }
`

class FactsScene8 extends Component {

  constructor(props) {
    super(props)

    this.state = {
      active: false,
    }
  }

  callback = active => {

    this.setState(prevState => ({
      active: !prevState.active,
    }))

  }

  displayScene = progress => {

    return (
      <div className={`sticky`}>
        <Content>
          <p>The sun in 'The Lion King' is constructed from</p>

          <p className={`fadeIn num ${this.state.active ? "active" : false}`}>
            43
          </p>
          <p className={`fadeIn ${this.state.active ? "active" : false}`}>
            aluminium ribs attached to each other with silk strips
          </p>
        </Content>

        <div className="ground-bg">
          <GatsbyImage image={this.props.data.ground.childImageSharp.gatsbyImageData}
            alt=""
            title=""
            className="image"
          />
        </div>

        <div className={`sun-bg ${this.state.active ? "active" : false}`}>
          <GatsbyImage image={this.props.data.sun.childImageSharp.gatsbyImageData}
            alt=""
            title=""
            className="image"
          />
        </div>

        <ButtonHold callback={this.callback} play={progress > 0} />
      </div>
    )
  }

  render() {
    let mql = window.matchMedia(media.sm).matches

    return (
      <Wrapper active={this.props.active} id="FactsWrapper8">
        <div id="FactsScene8" />
        <Controller>
          <Scene
            triggerElement="#FactsScene8"
            triggerHook="onLeave"
            duration={500}
            pin
            enabled={mql}
          >
            {progress => this.displayScene(progress)}
          </Scene>
        </Controller>
      </Wrapper>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        

        ground: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt8-ground-1920.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1440, quality: 100, placeholder: NONE, formats: [AUTO, WEBP, AVIF])
          }
        }

        sun: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt8-sun-1920.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1440, quality: 100, placeholder: NONE, formats: [AUTO, WEBP, AVIF])
          }
        }

        
        
      }
    `}
    render={data => (
      <FactsScene8
        data={data}
        active={props.active}
        title={props.title}
        callback={e => props.callback(e)}
      />
    )}
  />
)