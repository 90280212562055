import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { media } from "utils/Media"
import { Controller, Scene } from 'react-scrollmagic';
import { graphql, StaticQuery } from "gatsby";
import Absolute from "components/shared/Absolute"
import BgImage from 'components/shared/BgImage'

const Wrapper = styled.div`
  overflow: hidden;
  
  &.black-bg {
      background-color: ${props => props.theme.colors.black};
  }
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media screen and (max-width: ${props => props.theme.sizes.sm}) {
      padding-bottom: 0 !important;
    }
    
    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }

    &.auto-height {
        height: auto;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
`;

const BgContainer = styled.div`
  z-index: 9;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${props => props.progress * 1}
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 45vw;
  left: 4vw;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  p {
    color: #fff;
    font-size: 1.5em;
    font-family: ${props => props.theme.font.family.semiBold};

    &.xl {
      font-size: 14rem;
      line-height: 1;
    }
  }
`

class FactsScene5 extends Component {

    displayScene = (progress) => {

        return (
          <div className={`sticky`}>
            <Absolute style={{ top: 0 }}>
              <BgImage
                data={this.props.data}
                play={progress > 0}
                progress={progress}
                noTitleBar
              />

              <BgContainer
                progress={progress}
                
              >
                <BgImage
                  data={this.props.data}
                  play={progress > 0}
                  progress={progress}
                  noTitleBar
                  overrideDefaultImage
                />
              </BgContainer>

              <Content>
                <p>There are</p>
                <p className="xl">106</p>
                <p>ants on the Ant Hill Lady</p>
              </Content>
            </Absolute>
          </div>
        )

    }

    render() {

        let mql = window.matchMedia(media.sm).matches;

        return (
            <Wrapper active={this.props.active} id="FactsWrapper5">
                <div id="FactsScene5" />
                <Controller>
                    <Scene
                        triggerElement="#FactsScene5"
                        triggerHook="onLeave"
                        duration={2000}
                        pin
                        enabled={mql}

                    >
                        {(progress) => this.displayScene(progress)}
                    </Scene>
                </Controller>
            </Wrapper>
        )

    }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        bgStep1: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt5-1-1920.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 100)
          }
        }

        bgStep1Lg: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt5-1-1440.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1440, quality: 100)
          }
        }

        bgStep1Md: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt5-1-991.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 991, quality: 100)
          }
        }

        bgStep1Sm: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt5-1-576.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 575, quality: 100)
          }
        }

        bgStep2: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt5-2-1920.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 100)
          }
        }

        bgStep2Lg: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt5-2-1440.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1440, quality: 100)
          }
        }

        bgStep2Md: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt5-2-991.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 991, quality: 100)
          }
        }

        bgStep2Sm: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt5-2-576.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 575, quality: 100)
          }
        }
      }
    `}
    render={data => (
      <FactsScene5
        data={data}
        active={props.active}
        title={props.title}
        callback={e => props.callback(e)}
      />
    )}
  />
)