import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { media } from "utils/Media"
import { Controller, Scene } from 'react-scrollmagic';
import { graphql, StaticQuery } from "gatsby";
import BgImage from 'components/shared/BgImage'
import ButtonHold from "components/shared/ButtonHold"

import ruler from "images/Interactive/Facts/lke-im-ff-pt4-ruler.svg"

const Wrapper = styled.div`
  overflow: hidden;
  
  &.black-bg {
      background-color: ${props => props.theme.colors.black};
  }
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media screen and (max-width: ${props => props.theme.sizes.sm}) {
      padding-bottom: 0 !important;
    }
    
    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }

    &.auto-height {
        height: auto;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
`;

const Ruler = styled.img`
  z-index: 10;
  position: absolute;
  top: 50%;
  height: 110px;
  max-height: 110px;
  transform: translate(-550px, -50%);
  transition: all 0.7s ease-out;

  &.active {
    transform: translate(0px, -50%);
  }
`

const Content = styled.div`
  z-index: 10;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 4vw;

  .top {
    font-size: 1.5em;
    font-family: ${props => props.theme.font.family.semiBold};
    color: #fff;
    margin-bottom: 100px;
  }

  .dummy-img {
    width: 100%;
    height: 110px;
    display: block;
  }

  .bottom {
    color: #fff;
    font-size: 1.5em;
    font-family: ${props => props.theme.font.family.semiBold};
    margin-top: 100px;
  }
`


class FactsScene4 extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      active: false,
    }
  }



  callback = active => {
    this.setState(prevState => ({
      active: !prevState.active,
    }))
  }

  displayScene = progress => {
    return (
      <div className={`sticky`}>
        <BgImage
          data={this.props.data}
          play={progress > 0}
          progress={progress}
          noTitleBar
        />

        <Content>
          <p className="top">
            The smallest animal in the show is the
          </p>
          <div className="dummy-img"></div>
          <p className="bottom">
            trick mouse at the end of Scar's cane
          </p>
        </Content>

        <Ruler 
          className={this.state.active ? 'active' : false} 
          src={ruler} 
        />

        <ButtonHold callback={this.callback} play={true} />
      </div>
    )
  }

  render() {
    let mql = window.matchMedia(media.sm).matches

    return (
      <Wrapper active={this.props.active} id="FactsWrapper4">
        <div id="FactsScene4" />
        <Controller>
          <Scene
            triggerElement="#FactsScene4"
            triggerHook="onLeave"
            duration={500}
            pin
            enabled={mql}
          >
            {progress => this.displayScene(progress)}
          </Scene>
        </Controller>
      </Wrapper>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        bgStep1: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt4-1920.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 100)
          }
        }

        bgStep1Lg: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt4-1440.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1440, quality: 100)
          }
        }

        bgStep1Md: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt4-991.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 991, quality: 100)
          }
        }

        bgStep1Sm: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt4-576.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 575, quality: 100)
          }
        }

        
      }
    `}
    render={data => (
      <FactsScene4
        data={data}
        active={props.active}
        title={props.title}
        callback={e => props.callback(e)}
      />
    )}
  />
)