import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { media } from "utils/Media"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { graphql, StaticQuery } from "gatsby";
import ButtonScroll from "components/shared/ScrollButton"
import Absolute from "components/shared/Absolute"
import BgImage from 'components/shared/BgImage'

const Wrapper = styled.div`
  overflow: hidden;
  
  &.black-bg {
      background-color: ${props => props.theme.colors.black};
  }
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props =>
      props.active &&
      css`
        display: block !important;
      `}

    @media screen and (max-width: ${props => props.theme.sizes.sm}) {
      padding-bottom: 0 !important;
    }
    
    @media ${media.sm} {
        display: block !important;
    }
  }

  .absolute {
    top: 0;
  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props =>
      props.active &&
      css`
        height: 100vh;
      `}
    
    @media ${media.sm} {
        height: 100vh;
    }

    &.auto-height {
        height: auto;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 10;
    }
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 4vw;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.slideIn {
    top: auto;
    bottom: 0;
  }

  p {
    font-size: 1.5em;
    font-family: ${props => props.theme.font.family.semiBold};
    color: #fff;

    &.xl {
      font-size: 15rem;
      line-height: 0.8;
    }
  }
`

class FactsScene9 extends Component {

    displayScene = (progress) => {

        return (
          <div className={`sticky`}>
            <Absolute className="absolute">
              <BgImage
                data={this.props.data}
                play={progress > 0}
                progress={progress}
                noTitleBar
              />

              <Timeline totalProgress={progress} paused>
                <Tween
                  to={{ opacity: 0 }}
                  duration={2}
                  //totalProgress={progress}
                >
                  <Content>
                    <p className="xl">6</p>
                    <p>
                      African language are spoken or sung in the show, they are:
                    </p>
                  </Content>
                </Tween>

                <Tween
                  from={{
                    opacity: 0,
                    y: "100vh",
                  }}
                  to={{
                    opacity: 1,
                    y: "-50vh",
                    
                  }}
                  duration={15}
                  ease="Strong.easeOut"
                  
                >
                  <Content className="slideIn">
                    <p className="xl">Sotho</p>
                    <p className="xl">Swahili</p>
                    <p className="xl">Zulu</p>
                    <p className="xl">Tswana</p>
                    <p className="xl">Congoles</p>
                    <p className="xl">Xhosa</p>
                  </Content>
                </Tween>
              </Timeline>

              <ButtonScroll progress={progress} />
            </Absolute>
          </div>
        )

    }

    render() {

        let mql = window.matchMedia(media.sm).matches;

        return (
            <Wrapper active={this.props.active} id="FactsWrapper9">
                <div id="FactsScene9" />
                <Controller>
                    <Scene
                        triggerElement="#FactsScene9"
                        triggerHook="onLeave"
                        duration={2500}
                        pin

                    >
                        {(progress) => this.displayScene(progress)}
                    </Scene>
                </Controller>
            </Wrapper>
        )

    }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        bgStep1: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt9-1920.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 100)
          }
        }

        bgStep1Lg: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt9-1440.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1440, quality: 100)
          }
        }

        bgStep1Md: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt9-991.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 991, quality: 100)
          }
        }

        bgStep1Sm: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt9-576.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 575, quality: 100)
          }
        }
      }
    `}
    render={data => (
      <FactsScene9
        data={data}
        active={props.active}
        title={props.title}
        callback={e => props.callback(e)}
      />
    )}
  />
)