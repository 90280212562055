import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { media } from "utils/Media"
import { Controller, Scene } from 'react-scrollmagic';
import { graphql, StaticQuery } from "gatsby";
import Lottie from "react-lottie"
import BgImage from 'components/shared/BgImage'

import * as animationData from "./costumes.json"

const Wrapper = styled.div`
  overflow: hidden;
  
  &.black-bg {
      background-color: ${props => props.theme.colors.black};
  }
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media screen and (max-width: ${props => props.theme.sizes.sm}) {
      padding-bottom: 0 !important;
    }
    
    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }

    &.auto-height {
        height: auto;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 4vw;
  width: 45vw;
  height: 100vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    color: #fff;
    font-size: 2em;
    font-family: ${props => props.theme.font.family.semiBold};
  }

  .lottie-dummy {
    width: 50vw;
    height: 50vh;
  }
`

const LottieContainer = styled.div`
  position: absolute;
  top: 0;
  width: 50vw;
  height: 50vh;
`

class FactsScene6 extends Component {
  
  constructor(props) {
    super(props)
    // create a ref to store the textInput DOM element

    this.state = {
      active: false,
      isPaused: true,
    }
  }




  displayScene = progress => {


    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }

    return (
      <div className={`sticky`}>
        <BgImage
          data={this.props.data}
          play={progress > 0}
          progress={progress}
          noTitleBar
        />

        <Content>
          <p>There are over</p>

          <div className="lottie-dummy"/>
          <LottieContainer>
            <Lottie
              isPaused={progress > 0.2 ? false : true }
              options={defaultOptions}
              height={"300"}
              width={"300"}
            />
          </LottieContainer>

          <p>
            complete costumes in the show, each consisting of several different
            elements
          </p>
        </Content>
      </div>
    )
  }

  render() {
    let mql = window.matchMedia(media.sm).matches

    return (
      <Wrapper active={this.props.active} id="FactsWrapper6">
        <div id="FactsScene6" />
        <Controller>
          <Scene
            triggerElement="#FactsScene6"
            triggerHook="onLeave"
            duration={500}
            pin
            enabled={mql}
            
          >
            {progress => this.displayScene(progress)}
          </Scene>
        </Controller>
      </Wrapper>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        bgStep1: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt6-1920.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 100)
          }
        }

        bgStep1Lg: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt6-1440.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1440, quality: 100)
          }
        }

        bgStep1Md: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt6-991.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 991, quality: 100)
          }
        }

        bgStep1Sm: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt6-576.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 575, quality: 100)
          }
        }
      }
    `}
    render={data => (
      <FactsScene6
        data={data}
        active={props.active}
        title={props.title}
        callback={e => props.callback(e)}
      />
    )}
  />
)