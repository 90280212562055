import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { media } from "utils/Media"
import { Controller, Scene } from 'react-scrollmagic';
import { graphql, StaticQuery } from "gatsby";
import ButtonSwipe from "components/shared/ButtonSwipe"
import BgImage from 'components/shared/BgImage'

const Wrapper = styled.div`
  overflow: hidden;
  
  &.black-bg {
      background-color: ${props => props.theme.colors.black};
  }
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media screen and (max-width: ${props => props.theme.sizes.sm}) {
      padding-bottom: 0 !important;
    }
    
    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }

    &.auto-height {
        height: auto;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 4vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  //font-size: 3rem;

  color: #000;
  font-family: ${props => props.theme.font.family.semiBold};

  p {
    font-family: ${props => props.theme.font.family.semiBold};
    font-size: 1.5rem;
  }

  .xl {
    font-size: 7rem;
    line-height: 1;
  }
`

class FactsScene2 extends Component {
  constructor(props) {
    super(props)
    // create a ref to store the textInput DOM element
    
    this.state = {
      number: 0,
      active: false
    }

  }

  componentDidMount() {
    
    if (!this.state.active) {
      this.randomNumber();
    }


  }

  randomNumber() {
    
    if (!this.state.active) {

      this.timer = setInterval(() => {
        let number = Math.round(Math.random() * 100000)
        this.setState({
          number,
        })
      }, 250)

    }


  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  setNumber() {

    clearInterval(this.timer);
    this.setState({
      number: 35000
    })


  }

  callback = (active) => {

    if (active) {
      this.setNumber()
    } else {
      this.randomNumber();
    }

  }

  displayScene = progress => {

    let number = this.state.number;
    let value = number.toLocaleString("en-IN")

    return (
      <div className={`sticky`}>
        <BgImage
          data={this.props.data}
          play={progress > 0}
          progress={progress}
          noTitleBar
        />

        <Content>
          <p>It took director Julie Taymor</p>
          <div className="xl">{value}</div>
          <div className="xl">hours</div>
          <p>to build the original puppets and masks</p>
        </Content>

        <ButtonSwipe 
          callback={this.callback}
          play={true} 
        />

      </div>
    )
  }

  render() {
    let mql = window.matchMedia(media.sm).matches

    return (
      <Wrapper active={this.props.active} id="FactsWrapper2">
        <div id="FactsScene2" />
        <Controller>
          <Scene
            triggerElement="#FactsScene2"
            triggerHook="onLeave"
            duration={500}
            pin
            enabled={mql}
          >
            {progress => this.displayScene(progress)}
          </Scene>
        </Controller>
      </Wrapper>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        bgStep1: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt2-1920.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 100)
          }
        }

        bgStep1Lg: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt2-1440.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1440, quality: 100)
          }
        }

        bgStep1Md: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt2-991.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 991, quality: 100)
          }
        }

        bgStep1Sm: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt2-576.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 575, quality: 100)
          }
        }
      }
    `}
    render={data => (
      <FactsScene2
        data={data}
        active={props.active}
        title={props.title}
        callback={e => props.callback(e)}
      />
    )}
  />
)