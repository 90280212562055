import React, { Component } from "react"
import { media } from "utils/Media"
import styled from "styled-components"
import LayoutInteractive from "components/Layout/LayoutInteractive"
import ParallaxImage from "components/Interactive/ParallaxImage"
import Menu from "components/Interactive/Menu"
import TitleBar from 'components/shared/TitleBar'
import ActTitle from "components/Interactive/ActTitle"

import FactsScene1 from "components/Interactive/Facts/Scene1"
import FactsScene2 from "components/Interactive/Facts/Scene2"
import FactsScene3 from "components/Interactive/Facts/Scene3"
import FactsScene4 from "components/Interactive/Facts/Scene4"
import FactsScene5 from "components/Interactive/Facts/Scene5"
import FactsScene6 from "components/Interactive/Facts/Scene6"
import FactsScene7 from "components/Interactive/Facts/Scene7"
import FactsScene8 from "components/Interactive/Facts/Scene8"
import FactsScene9 from "components/Interactive/Facts/Scene9"

import DownloadButton from "components/shared/DownloadButton"
import IconDownload from 'images/icons/icon-download-white.svg'

import { graphql, StaticQuery } from "gatsby";
import { isBrowser, isChrome } from "react-device-detect";

const MobileTitleBar = styled(TitleBar)`
    display: block;
    cursor: pointer;
    
    &.hidden {
        display: none;
    }
    
    @media ${media.sm} {
        display: none;
        
        &.hidden {
            display: block;
        }
    }
`

const scrollToElement = require('scroll-to-element');

const Query = () => (
  <StaticQuery
    query={graphql`
      query {
        image: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-header-1920.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 100)
          }
        }

        imageLg: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-header-1440.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1440, quality: 100)
          }
        }

        imageSm: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-header-991.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 991, quality: 100)
          }
        }

        imageXs: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-header-576.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 575, quality: 100)
          }
        }
      }
    `}
    render={data => <FascinatingFactsPage data={data} />}
  />
)

class FascinatingFactsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scene: '',
            displayContent: false,
            imagesAreLoaded: [],
            activeSceneName: "Facts",
            activePageName: "4/4 Fascinating Facts",
            sceneList: [],
            pageList: [
                {
                    name: "1/3 The Story",
                    slug: "/interactive/the-story",
                    active: false
                },
                {
                    name: "2/3 Meet the Characters",
                    slug: "/interactive/meet-the-characters",
                    active: false
                },
                {
                    name: "3/3 About The Production",
                    slug: "/interactive/about-the-production",
                    active: false,
                },
                // {
                //     name: "4/4 Fascinating Facts",
                //     slug: "/interactive/fascinating-facts",
                //     active: true,
                // }
            ]
        }
    }

    toggleScene = (scene) => {
        this.setState({scene}, () => {
            scrollToElement(`#${scene}Scene`, {
                duration: 300
            })
        })

    }

    imagesLoaded = (isLoaded) => {
        let imagesAreLoaded = this.state.imagesAreLoaded
        let displayContent = this.state.displayContent

        imagesAreLoaded.push(isLoaded)

        if (isBrowser) {
            if (isChrome && imagesAreLoaded.length > 3) {
                displayContent = true
            } else if (!isChrome && imagesAreLoaded.length === 1) {
                displayContent = true
            }
        } else {
            displayContent = true
        }

        this.setState({imagesAreLoaded, displayContent})
    }

    render() {
        return (
          <LayoutInteractive slug="interactive/fascinating-facts" title="Fascinating Facts">
            <Menu
              sceneList={this.state.sceneList}
              activeSceneName={this.state.activeSceneName}
              pageList={this.state.pageList}
            />
            <ParallaxImage
              imagesAreLoaded={this.imagesLoaded}
              data={this.props.data}
              title="Fascinating Facts"
            />

            {this.state.displayContent && (
              <React.Fragment>
                <FactsScene1 active={true} />
                <FactsScene2 active={true} />
                <FactsScene3 active={true} />
                <FactsScene4 active={true} />
                <FactsScene5 active={true} />
                <FactsScene6 active={true} />
                <FactsScene7 active={true} />
                <FactsScene8 active={true} />
                <FactsScene9 active={true} />
              </React.Fragment>
            )}
              <DownloadButton className="mx-auto mb-4 mt-5" download target="_blank" href="/pdfs/Disneys_The_Lion_King_Fascinating_Facts.pdf">Download&nbsp;PDF <img src={IconDownload} alt="" /></DownloadButton>
          </LayoutInteractive>
        )
    }
}

export default Query