import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { media } from "utils/Media"
import { Controller, Scene } from 'react-scrollmagic';
import { graphql, StaticQuery } from "gatsby";
import BgImage from 'components/shared/BgImage'
import ButtonHold from "components/shared/ButtonHold"

const Wrapper = styled.div`
  overflow: hidden;
  
  &.black-bg {
      background-color: ${props => props.theme.colors.black};
  }
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media screen and (max-width: ${props => props.theme.sizes.sm}) {
      padding-bottom: 0 !important;
    }
    
    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }

    &.auto-height {
        height: auto;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 4vw;
  width: 50vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  //font-size: 3rem;
  transition: all 0.7s ease-out;

  color: #fff;
  font-family: ${props => props.theme.font.family.semiBold};

  p {
    font-family: ${props => props.theme.font.family.semiBold};
    font-size: 1.5rem;
  }

  .xl {
    font-size: 8rem;
    line-height: 1;
  }

  &.fade {
    opacity: 0.5;
  }
`

class FactsScene3 extends Component {

  constructor(props) {
    super(props)
    
    this.state = {
      active: false
    }

  }

    callback = (active) => {

      this.setState((prevState) => ({
        active: !prevState.active
      }))

    }

    displayScene = (progress) => {

        return (
          <div className={`sticky`}>
            <BgImage
              data={this.props.data}
              play={progress > 0}
              progress={progress}
              noTitleBar
            />

            <Content className={this.state.active ? 'fade' : false}>

              <p>Thee are</p>
              <p className="xl">25</p>
              <p>species of animals represented in the production</p>

            </Content>

            <ButtonHold 
              callback={this.callback}
              play={true} 
            />

            
          </div>
        )

    }

    render() {

        let mql = window.matchMedia(media.sm).matches;

        return (
            <Wrapper active={this.props.active} id="FactsWrapper3">
                <div id="FactsScene3" />
                <Controller>
                    <Scene
                        triggerElement="#FactsScene3"
                        triggerHook="onLeave"
                        duration={500}
                        pin
                        enabled={mql}

                    >
                        {(progress) => this.displayScene(progress)}
                    </Scene>
                </Controller>
            </Wrapper>
        )

    }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        bgStep1: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt3-1920.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 100)
          }
        }

        bgStep1Lg: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt3-1440.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1440, quality: 100)
          }
        }

        bgStep1Md: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt3-991.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 991, quality: 100)
          }
        }

        bgStep1Sm: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt3-576.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 575, quality: 100)
          }
        }

        bgStep2: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt3-1920.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 100)
          }
        }

        bgStep2Lg: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt3-1440.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1440, quality: 100)
          }
        }

        bgStep2Md: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt3-991.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 991, quality: 100)
          }
        }

        bgStep2Sm: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt3-576.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 575, quality: 100)
          }
        }
      }
    `}
    render={data => (
      <FactsScene3
        data={data}
        active={props.active}
        title={props.title}
        callback={e => props.callback(e)}
      />
    )}
  />
)