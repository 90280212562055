import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { media } from "utils/Media"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { graphql, StaticQuery } from "gatsby";
import Lottie from "react-lottie"
import ButtonSwipe from "components/shared/ButtonSwipe"
import BgImage from 'components/shared/BgImage'

import * as animationData from "./puppets.json"

const Wrapper = styled.div`
  overflow: hidden;
  
  &.black-bg {
      background-color: ${props => props.theme.colors.black};
  }
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media screen and (max-width: ${props => props.theme.sizes.sm}) {
      padding-bottom: 0 !important;
    }
    
    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }

    &.auto-height {
        height: auto;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }


`;

const Content = styled.div`
  position: absolute;
  top: 50px;
  left: 50vw;
  color: #fff;
  z-index: 10;
  width: 45vw;
  text-align: center;

  font-size: 2em;
  font-family: ${props => props.theme.font.family.semiBold};

  @media screen and (max-width: ${props => props.theme.sizes.sm}) {
    font-size: 1.4em;
    left: 2vw;
    width: 90vw;
    text-align: left;
  }

  .heading1 {
  }

  .heading2 {
    position: absolute;
    top: 65vh;
  }
`

const LottieContainer = styled.div`
  width: 50vw;
  height: 50vh;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  @media screen and (max-width: ${props => props.theme.sizes.sm}) {
    left: 2vw;
    width: 100vw;
    height: 100vh;
  }

`

class FactsScene1 extends Component {
  constructor(props) {
    super(props)
    // create a ref to store the textInput DOM element

    this.state = {
      active: false,
      isPaused: true
    }

  }

  callback = active => {
    this.setState(prevState => ({
      isPaused: !prevState.isPaused,
    }))
  }

  displayScene = progress => {

    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }

    return (
      <div className={`sticky`}>

        <BgImage
          data={this.props.data}
          play={progress > 0}
          progress={progress}
          noTitleBar
        />

        <Content>
          <div className="heading1">There are more than</div>

          <div className="heading2">
            masks and puppets in the show, including rod puppets, shadow puppets
            and full-sized puppets
          </div>
        </Content>

        <LottieContainer>
          <Lottie
            isPaused={this.state.isPaused}
            options={defaultOptions}
            height={"300"}
            width={"300"}
          />
        </LottieContainer>

        <ButtonSwipe callback={this.callback} play={true} />
      </div>
    )
  }

  render() {
    let mql = window.matchMedia(media.sm).matches

    return (
      <Wrapper active={this.props.active} id="FactsWrapper1">
        
        <div id="FactsScene1" />
        <Controller>
          <Scene
            triggerElement="#FactsScene1"
            triggerHook="onLeave"
            duration={500}
            pin
            enabled={mql}
          >
            {progress => this.displayScene(progress)}
          </Scene>
        </Controller>
      </Wrapper>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        bgStep1: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt1-1920.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 100)
          }
        }

        bgStep1Lg: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt1-1440.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 1440, quality: 100)
          }
        }

        bgStep1Md: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt1-2-991.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 991, quality: 100)
          }
        }

        bgStep1Sm: file(
          relativePath: { eq: "Interactive/Facts/lke-im-ff-pt1-2-576.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 575, quality: 100)
          }
        }
      }
    `}
    render={data => (
      <FactsScene1
        data={data}
        active={props.active}
        title={props.title}
        callback={e => props.callback(e)}
      />
    )}
  />
)